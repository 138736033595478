@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
	color-scheme: dark;
}

#root {
	height: 100%;
}

*::-webkit-scrollbar {
	width: 0;
}

html,
body {
	margin: 0;
	background: #1a1732;
	height: 100%;
	overflow: hidden;
}

.rainbowLetter {
	background: linear-gradient(
		to bottom right,
		#ff6b6b,
		#ffa45c,
		#ffd56b,
		#4dbead,
		#6196b3
	);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	display: inline-block;
	margin-right: 1px;
}
.disabledLetter {
	background: none;
	color: grey;
}

.white-text-markdown * {
	color: white !important; /* Force white color, overriding other styles */
}
