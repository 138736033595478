@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/*! GitHub: Vibrant Ink */
:root {
  --ghd-code-background: #0f0f0f;
  --ghd-code-color: #fff;
}
.pl-c, .pl-c span { color: #93c !important; font-style: italic !important; } /* comment */
.pl-c1 { color: #399 !important; } /* constant */
.pl-e { color: #f60 !important; } /* entity */
.pl-en { color: #f60 !important; } /* entity.name */
.pl-ent { color: #fc0 !important; } /* entity.name.tag */
.pl-k { color: #9c9 !important; } /* keyword */
.pl-mb { color: #6f0 !important; font-weight: bold !important; } /* markup.bold */
.pl-mdh { color: #f60 !important; } /* meta.diff.header */
.pl-mdr { color: #f60 !important; } /* meta.diff.range */
.pl-mh { color: #9c9 !important; } /* markup.heading */
.pl-mh .pl-en { color: #9c9 !important; font-weight: bold !important; } /* markup.heading entity.name */
.pl-mi { color: #f60 !important; font-style: italic !important; } /* markup.italic */
.pl-ml { color: #f60 !important; } /* markup.list */
.pl-mm { color: #f60 !important; } /* meta.module-reference */
.pl-mo { color: #f60 !important; } /* meta.output */
.pl-mp { color: #f60 !important; } /* meta.property-name */
.pl-mq { color: #93c !important; } /* markup.quote */
.pl-mr { color: #f60 !important; } /* meta.require */
.pl-ms { color: #f60 !important; } /* meta.selector */
.pl-pds { color: #6f0 !important; } /* punctuation.definition.string */
.pl-s { color: #fc0 !important; } /* storage */
.pl-s1 { color: #6f0 !important; } /* string */
.pl-s1 .pl-pse .pl-s2 { color: #6f0 !important; } /* string punctuation.section.embedded source */
.pl-s1 .pl-s2 { color: #399 !important; } /* string source */
.pl-s1 .pl-v { color: #f60 !important; } /* string variable */
.pl-s3 { color: #fc0 !important; } /* support */
.pl-sc { color: #fff !important; } /* support.class */
.pl-smi { color: #399 !important; } /* storage.modifier.import */
.pl-smp { color: #399 !important; } /* storage.modifier.package */
.pl-sr { color: #44b4cc !important; } /* string.regexp */
.pl-sr .pl-cce { color: #9c9 !important; } /* constant.character.escape */
.pl-sr .pl-sra { color: #44b4cc !important; } /* string.regexp string.regexp.arbitrary-repetition */
.pl-sr .pl-sre { color: #44b4cc !important; } /* string.regexp source.ruby.embedded */
.pl-src { color: #6f0 !important; } /* string.regexp.character-class */
.pl-st { color: #f60 !important; } /* support.type */
.pl-stj { color: #399 !important; } /* storage.type.java */
.pl-sv { color: #6f0 !important; } /* support.variable */
.pl-v { color: #fc0 !important; } /* variable */
.pl-vo { color: #399 !important; } /* variable.other */
.pl-vpf { color: #fff !important; } /* variable.parameter.function */
/* diff - example: https://gist.github.com/silverwind/904159f1e71e2e626375 */
.pl-mi1 { color: #fff !important; background: rgba(0,64,0,.5) !important; } /* markup.inserted */
.pl-mdht { color: #fff !important; background: rgba(0,64,0,.5) !important; } /* meta.diff.header.to-file */
.pl-md { color: #fff !important; background: rgba(64,0,0,.5) !important; } /* markup.deleted */
.pl-mdhf { color: #fff !important; background: rgba(64,0,0,.5) !important; } /* meta.diff.header.from-file */
.pl-bu, /* invalid.broken, invalid.deprecated, invalid.unimplemented, message.error, brackethighlighter.unmatched, sublimelinter.mark.error */
.pl-ii { color: #f60 !important; background: rgba(64,0,0,1) !important; } /* invalid.illegal */
.pl-corl, .highlight .pl-corl span.x { color: #fc0 !important; text-decoration: underline !important; } /* links */
/* language tweaks */
.highlight-source-css .pl-c1 { color: #9c9 !important; } /* constant */
.highlight-source-css .pl-ent { color: #399 !important; } /* entity.name.tag */
.highlight-source-css .pl-s3 { color: #fff !important; } /* support */
.highlight-source-css .pl-sc { color: #399 !important; } /* support.class */
.highlight-text-html-basic .pl-ent { color: #f60 !important; } /* entity.name.tag */
.highlight-text-html-basic .pl-e { color: #9c9 !important; } /* entity */
.highlight-source-js .pl-vpf { color: #fc0 !important; } /* variable.parameter.function */
.highlight-text-html-php .pl-s { color: #f60 !important; } /* storage */
.highlight-text-html-php .pl-s3 { color: #f60 !important; } /* support */
.highlight-text-html-php .pl-vo { color: #fc0 !important; } /* variable.other */
.highlight-source-c\+\+ .pl-s3 { color: #fff !important; } /* support */
.pl-token.active, .pl-token:hover { background: #fff !important; color: #f60 !important; }
pre { background: #161b22; padding: 16px; border-radius: 5px; }
:root {
	color-scheme: dark;
}

#root {
	height: 100%;
}

*::-webkit-scrollbar {
	width: 0;
}

html,
body {
	margin: 0;
	background: #1a1732;
	height: 100%;
	overflow: hidden;
}

.rainbowLetter {
	background: linear-gradient(
		to bottom right,
		#ff6b6b,
		#ffa45c,
		#ffd56b,
		#4dbead,
		#6196b3
	);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
	display: inline-block;
	margin-right: 1px;
}
.disabledLetter {
	background: none;
	color: grey;
}

.white-text-markdown * {
	color: white !important; /* Force white color, overriding other styles */
}

